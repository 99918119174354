import FooterBar from "./Components/FooterBar";
import HeaderBar from "./Components/HeaderBar";

type LayoutProps = {
  children: JSX.Element | JSX.Element[];
};

const Layout = ({ children }: LayoutProps) => {
  return (
    <>
      <HeaderBar />
      {children}
      <FooterBar />
    </>
  );
};
export default Layout;
