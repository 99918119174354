import React from "react";
import {
  ThemeIcon,
  Text,
  Title,
  Container,
  SimpleGrid,
  createStyles,
} from "@mantine/core";
import {
  IconGauge,
  IconCookie,
  IconUser,
  IconMessage2,
  IconLock,
  IconRobot,
} from "@tabler/icons-react";
import { useLanguage } from "./languageContext";

export const MOCKDATA = [
  {
    icon: IconGauge,
    titleKey: "versatilityTitle",
    descriptionKey: "versatilityDescription",
  },
  {
    icon: IconUser,
    titleKey: "adaptabilityTitle",
    descriptionKey: "adaptabilityDescription",
  },
  {
    icon: IconMessage2,
    titleKey: "communicationTitle",
    descriptionKey: "communicationDescription",
  },
  {
    icon: IconLock,
    titleKey: "qualityTitle",
    descriptionKey: "qualityDescription",
  },
  {
    icon: IconCookie,
    titleKey: "innovationTitle",
    descriptionKey: "innovationDescription",
  },
  {
    icon: IconRobot,
    titleKey: "aiTitle",
    descriptionKey: "aiDescription",
  },
];

interface FeatureProps {
  icon: React.FC<any>;
  titleKey: string;
  descriptionKey: string;
}

function Feature({ icon: Icon, titleKey, descriptionKey }: FeatureProps) {
  const { t } = useLanguage();

  return (
    <div>
      <ThemeIcon variant="light" size={40} radius={40}>
        <Icon size="1.1rem" stroke={1.5} />
      </ThemeIcon>
      <Text mt="sm" mb={7}>
        {t(titleKey)}
      </Text>
      <Text size="sm" color="dimmed" sx={{ lineHeight: 1.6 }}>
        {t(descriptionKey)}
      </Text>
    </div>
  );
}

const useStyles = createStyles((theme) => ({
  wrapper: {
    // paddingTop: `calc(${theme.spacing.xl} * 4)`,
    paddingBottom: `calc(${theme.spacing.xl} * 4)`,
  },

  // title: {
  //   fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  //   fontWeight: 900,
  //   marginBottom: theme.spacing.md,
  //   textAlign: "center",

  //   [theme.fn.smallerThan("sm")]: {
  //     fontSize: 28,
  //     textAlign: "left",
  //   },
  // },

  description: {
    textAlign: "center",

    [theme.fn.smallerThan("sm")]: {
      textAlign: "left",
    },
  },
}));

interface FeaturesGridProps {
  titleKey?: string;
  descriptionKey?: string;
  data?: FeatureProps[];
}

export function FeaturesGrid({
  titleKey = "strengthsTitle",
  descriptionKey = "",
  data = MOCKDATA,
}: FeaturesGridProps) {
  const { classes } = useStyles();
  const { t } = useLanguage();

  const features = data.map((feature, index) => (
    <Feature {...feature} key={index} />
  ));

  return (
    <Container className={classes.wrapper}>
      <Title>{titleKey ? t(titleKey) : ""}</Title>

      <Container size={560} p={0}>
        <Text size="sm" className={classes.description}>
          {descriptionKey ? t(descriptionKey) : ""}
        </Text>
      </Container>

      <SimpleGrid
        mt={60}
        cols={3}
        spacing={50}
        breakpoints={[
          { maxWidth: 980, cols: 2, spacing: "xl" },
          { maxWidth: 755, cols: 1, spacing: "xl" },
        ]}
      >
        {features}
      </SimpleGrid>
    </Container>
  );
}
