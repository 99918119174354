// import React, { useState } from "react";
// import logo from "./logo.svg";
import "./App.css";
import { LanguageProvider } from "./Components/languageContext";
import Router from "./router";
import {
  ColorScheme,
  ColorSchemeProvider,
  MantineProvider,
} from "@mantine/core";
import { useLocalStorage } from "@mantine/hooks";

// merge at some point the app and router and index.tsx pages into 2 pages

function App() {
  const [colorScheme, setColorScheme] = useLocalStorage<ColorScheme>({
    key: "mantine-color-scheme",
    defaultValue: "dark",
    getInitialValueInEffect: true,
  });

  const toggleColorScheme = (value?: ColorScheme) => {
    const nextColorScheme =
      value || (colorScheme === "dark" ? "light" : "dark");
    setColorScheme(nextColorScheme);
    // setCookie('mantine-color-scheme', nextColorScheme, { maxAge: 60 * 60 * 24 * 30 });
  };

  return (
    <div className="App">
      <ColorSchemeProvider
        colorScheme={colorScheme}
        toggleColorScheme={toggleColorScheme}
      >
        <MantineProvider
          theme={{
            colorScheme,
            colors: {
              dark: [
                "#d5d7e0",
                "#acaebf",
                "#8c8fa3",
                "#666980",
                "#4d4f66",
                "#34354a",
                "#2b2c3d",
                "#100022", // changed to purple from 1d1e30
                "#0c0d21",
                "#01010a",
              ],
            },
          }}
          withGlobalStyles
          withNormalizeCSS
        >
          <LanguageProvider>
            <Router />
          </LanguageProvider>
        </MantineProvider>
      </ColorSchemeProvider>
    </div>
  );
}

export default App;
