import { Title, useMantineTheme } from "@mantine/core";
import v from "./VoiLabs/Assets/v.png";
import oilabs from "./VoiLabs/Assets/oilabs.png";
import oilabsBlack from "./VoiLabs/Assets/oilabs-black.png";

const Logo = () => {
  const voilabs = ["/voilabs", "/ai"].includes(window.location.pathname);
  const theme = useMantineTheme();

  if (voilabs) {
    return (
      <div style={{ fontSize: 21, fontWeight: 700, display: "flex" }}>
        <Title order={1} style={{ fontSize: 50, fontWeight: 450 }}>
          <img
            src={v}
            alt="VoiLabs"
            style={{
              height: 50,
              marginLeft: 20,
              marginBottom: 10,
              marginRight: -5,
            }}
          />
          <img
            src={theme.colorScheme === "dark" ? oilabs : oilabsBlack}
            alt="VoiLabs"
            style={{
              height: 50,
              marginLeft: -9,
              marginBottom: 10,
              marginRight: -5,
            }}
          />
        </Title>
      </div>
    );
  }

  return (
    <div style={{ fontSize: 21, fontWeight: 700, display: "flex" }}>
      <Title order={2}>Benjamin Merchin</Title>
    </div>
  );
};
export default Logo;
