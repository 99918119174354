import { createStyles, Title, Text, Button, Container } from "@mantine/core";
import { Dots } from "./Dots";
import { useMantineTheme } from "@mantine/core";
import { useLanguage } from "./languageContext";

const rem = (pixels: number) => pixels * 1;

const useStyles = createStyles((theme) => ({
  wrapper: {
    position: "relative",
    paddingTop: rem(180),
    paddingBottom: rem(180),
    // background: "linear-gradient(90deg, #FAA77A, #36BA64, #9E0DD2)",
    // backgroundSize: "300% 300%",
    // animation: "gradient 4s alternate infinite",

    [theme.fn.smallerThan("sm")]: {
      paddingTop: rem(120),
      paddingBottom: rem(120),
    },
  },

  inner: {
    position: "relative",
    zIndex: 1,
  },

  dots: {
    position: "absolute",
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[5]
        : theme.colors.gray[1],

    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },

  dotsLeft: {
    left: 0,
    top: 0,
  },

  title: {
    textAlign: "center",
    fontWeight: 800,
    fontSize: rem(40),
    letterSpacing: -1,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    marginBottom: theme.spacing.xs,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,

    [theme.fn.smallerThan("xs")]: {
      fontSize: rem(28),
      textAlign: "left",
    },
  },

  highlight: {
    color:
      theme.colors[theme.primaryColor][theme.colorScheme === "dark" ? 4 : 6],
  },

  description: {
    textAlign: "center",

    [theme.fn.smallerThan("xs")]: {
      textAlign: "left",
      fontSize: theme.fontSizes.md,
    },
  },

  controls: {
    marginTop: theme.spacing.lg,
    display: "flex",
    justifyContent: "center",

    [theme.fn.smallerThan("xs")]: {
      flexDirection: "column",
    },
  },

  control: {
    "&:not(:first-of-type)": {
      marginLeft: theme.spacing.md,
    },

    [theme.fn.smallerThan("xs")]: {
      height: rem(42),
      fontSize: theme.fontSizes.md,

      "&:not(:first-of-type)": {
        marginTop: theme.spacing.md,
        marginLeft: 0,
      },
    },
  },
}));

export function CTA() {
  const { classes } = useStyles();
  const { t } = useLanguage();

  return (
    <div className={classes.controls}>
      <Button
        className={classes.control}
        size="lg"
        variant="default"
        color="gray"
        onClick={() =>
          window.open("mailto:benjaminmerchin@gmail.com", "_blank")
        }
      >
        {t("sendEmail")}
      </Button>
      <Button
        className={classes.control}
        size="lg"
        onClick={() =>
          window.open("https://calendly.com/merchin/30min", "_blank")
        }
      >
        {t("scheduleCall")}
      </Button>
    </div>
  );
}

export function HeroText() {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const { t } = useLanguage();

  const imageUrl =
    theme.colorScheme === "dark"
      ? "https://wallpapercave.com/wp/wp2831093.jpg"
      : "https://wallpaperaccess.com/full/316662.jpg";

  const wrapperStyle = {
    backgroundImage: `url(${imageUrl})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    clipPath: "polygon(0 0, 100% 0, 100% calc(100% - 100px), 0 100%)",
  };

  return (
    <Container
      className={classes.wrapper}
      style={wrapperStyle}
      size={window.innerWidth}
    >
      <Dots className={classes.dots} style={{ left: 0, top: 0 }} />
      <Dots className={classes.dots} style={{ left: 60, top: 0 }} />
      <Dots className={classes.dots} style={{ left: 0, top: 140 }} />
      <Dots className={classes.dots} style={{ right: 0, top: 60 }} />

      <div className={classes.inner}>
        <Title className={classes.title}>
          {t("freelance")}{" "}
          <Text component="span" className={classes.highlight} inherit>
            {t("agile")}
          </Text>{" "}
          {t("forAllYourProjects")}
        </Title>

        <Container p={0} size={600}>
          <Text
            size="lg"
            className={classes.description}
            style={{ fontWeight: 700 }}
          >
            {t("heroDescription")}
          </Text>
        </Container>
      </div>

      <CTA />
    </Container>
  );
}
