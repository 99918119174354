import {
  Button,
  Container,
  Grid,
  Image,
  Text,
  Title,
  createStyles,
} from "@mantine/core";
// import { CTA } from "../Components/HeroText";
import { HeroBullets } from "../Components/VoiLabs/HeroBullets";
import work from "../Components/VoiLabs/Assets/work.png";
import { FeatureVoiLabs } from "../Components/VoiLabs/Assets/FeatureVoiLabs";
import { useNavigate } from "react-router-dom";

const useStyles = createStyles((theme) => ({
  control: {
    [theme.fn.smallerThan("xs")]: {
      flex: 1,
    },
  },
}));

const VoiLabs = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();

  return (
    <>
      <HeroBullets />

      <Container size="lg">
        <Grid m={0} mt={50} align="center">
          <Grid.Col xs={5}>
            <Image maw={340} mx="auto" radius="md" src={work} alt="work" />
          </Grid.Col>
          <Grid.Col xs={6}>
            <Text style={{ textAlign: "left", fontSize: 18 }}>
              <Title style={{ justifyContent: "left", marginBottom: 10 }}>
                Product Overview
              </Title>
              Our AI Customer Service Assistant understands your business to
              offer quick, personalized and relevant responses to your
              customers' queries. Available around the clock, it handles all
              routine tasks, enabling your team to focus on the issues that
              really matter.
            </Text>
          </Grid.Col>
        </Grid>
        <FeatureVoiLabs />
        {/* <div style={{ paddingRight: 20, paddingLeft: 20 }}>
        <CTA />
      </div> */}
        <Button
          radius="xl"
          size="md"
          className={classes.control}
          color="violet"
          onClick={() => navigate("/ai")}
        >
          Speak to our AI
        </Button>
      </Container>
    </>
  );
};
export default VoiLabs;
