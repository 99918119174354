// useApi.ts
// import { useContext } from "react";
// import { UserContext } from "./UserContext";
import axios from "axios";

let baseUrl;

if (process.env.NODE_ENV === "production") {
  baseUrl = "";
  console.log("Wrong backend URL");
} else {
  baseUrl = "http://localhost:8000";
}

export const backendUrl = baseUrl;

const api = axios.create({
  baseURL: baseUrl, // Replace with your backend API's base URL
  withCredentials: true, // Send cookies with requests
});

export function useApi() {
  // const { setUser } = useContext(UserContext);

  // api.interceptors.request.use((config) => {
  //   const user = JSON.parse(localStorage.getItem("user") || "{}");
  //   if (user && user.accessToken) {
  //     config.headers["Authorization"] = `Bearer ${user.accessToken}`;
  //   }
  //   return config;
  // });

  api.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response && error.response.status === 401) {
        // If a request is unauthorized, clear the user's local authentication data
        // localStorage.removeItem("user");
        // setUser(null);
      }
      return Promise.reject(error);
    },
  );

  return api;
}
