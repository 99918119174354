import { Container, Grid, Image } from "@mantine/core";
import robot from "../Components/VoiLabs/Assets/robot.jpg";
import call from "../Components/VoiLabs/Assets/call.png";
import hangUp from "../Components/VoiLabs/Assets/hang-up.png";
import { useEffect, useRef, useState } from "react";
import { useApi } from "../useApi";
import { postTextToAnswer } from "../apiRoutes";
import { useViewportSize } from "@mantine/hooks";

// const useStyles = createStyles((theme) => ({
//   control: {
//     [theme.fn.smallerThan("xs")]: {
//       flex: 1,
//     },
//   },
// }));

const messagesInit = [
  {
    role: "system",
    content:
      "You are an advisor. Please respond in less than 5 complete sentences. Always ask if it was clear of are there other topics he can help with. Start the message with `Hi! How are you doing?`",
  },
];

const AI = () => {
  // const { classes } = useStyles();
  const messagesRef = useRef(messagesInit);
  const recognitionRef = useRef<SpeechRecognition | null>(null);
  const [isListening, setIsListening] = useState(false);
  const [processRunning, setProcessRunning] = useState(false);
  const api = useApi();
  const [boxShadow, setBoxShadow] = useState("");
  const { width } = useViewportSize();

  useEffect(() => {
    const interval = setInterval(() => {
      const spread = Math.random() * 10; // Random spread up to 10px
      const blur = Math.random() * 20; // Random blur up to 20px
      setBoxShadow(`0 0 ${blur}px ${spread}px rgba(128, 0, 128, 0.7)`);
    }, 200); // Change every second

    return () => clearInterval(interval); // Clean up on unmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetProcess = () => {
    if (recognitionRef.current) {
      recognitionRef.current.stop();
      recognitionRef.current = null;
    }
    setIsListening(false);
    setProcessRunning(false);
    messagesRef.current = messagesInit;
  };

  const sendTranscript = async (finalTranscript: string) => {
    setIsListening(false);
    const newMessages = [
      ...messagesRef.current,
      { role: "user", content: finalTranscript },
    ];
    const response = await postTextToAnswer(api, newMessages);
    if (!processRunning) return;
    const responseMessages = response.messages;
    messagesRef.current = responseMessages;
    const lastMessage = response.messages[response.messages.length - 1];

    // Decode base64 audio and play
    if (response.audio && lastMessage && lastMessage.role === "assistant") {
      let audioBase64 = response.audio;
      let audioBlob = new Blob(
        [
          new Uint8Array(
            atob(audioBase64)
              .split("")
              .map(function (c) {
                return c.charCodeAt(0);
              })
          ),
        ],
        { type: "audio/mpeg" }
      );
      let audioURL = URL.createObjectURL(audioBlob);
      let audio = new Audio(audioURL);

      audio.onended = () => {
        if (!isListening) {
          startSpeechRecognition(); // Restart the speech recognition
        }
      };

      audio.play();
    } else {
      if (!isListening) {
        startSpeechRecognition();
      }
    }
  };

  const startSpeechRecognition = () => {
    if (!("webkitSpeechRecognition" in window)) {
      alert(
        "Your browser does not support the Web Speech API. Please switch to Chrome or Firefox."
      );
      return;
    }
    const recognition = new webkitSpeechRecognition();
    recognition.interimResults = false;
    recognition.continuous = false;
    recognition.onresult = function (event: SpeechRecognitionEvent) {
      const results = event.results;
      const finalTranscript = results[results.length - 1][0].transcript;
      sendTranscript(finalTranscript);
    };
    recognition.onerror = (event) => {
      console.log("Recognition Error: ", event.error);
      resetProcess();
    };
    recognition.start();
    recognitionRef.current = recognition;
    setIsListening(true);
  };

  useEffect(() => {
    return () => {
      if (recognitionRef.current) {
        recognitionRef.current.stop();
        recognitionRef.current = null;
        setIsListening(false);
      }
    };
  }, []);

  const changeProcessRunning = () => {
    if (!processRunning) {
      startSpeechRecognition();
    } else {
      resetProcess();
    }
    setProcessRunning(!processRunning);
  };
  return (
    <Container
      size="lg"
      style={{
        display: "flex",
        flexDirection: "column",
        height: width < 576 ? undefined : "calc(100vh - 257px)",
        minHeight: "400px",
        justifyContent: "center",
      }}
    >
      {/* <div>
        <Button
          radius="xl"
          size="md"
          className={classes.control}
          color="violet"
          onClick={changeProcessRunning}
          ml={30}
          mr={30}
        >
          {processRunning ? "Stop" : "Start"}
        </Button>
      </div> */}
      <Grid m={0} align="center">
        <Grid.Col xs={6}>
          <Image
            maw={340}
            mx="auto"
            radius={1000}
            src={robot}
            alt="demo"
            style={{
              borderRadius: "50%",
              boxShadow: processRunning && !isListening ? boxShadow : undefined,
            }}
          />
        </Grid.Col>
        <Grid.Col
          xs={6}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              width: 340,
              height: 340,
              backgroundColor: "#D0E0FD",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: 75,
              color: "#5F3CC4",
              boxShadow: processRunning && isListening ? boxShadow : undefined,
            }}
          >
            You
          </div>
        </Grid.Col>
      </Grid>
      <Image
        maw={70}
        mx="auto"
        src={processRunning ? hangUp : call}
        alt=""
        onClick={changeProcessRunning}
        style={{ cursor: "pointer" }}
      />
    </Container>
  );
};
export default AI;
