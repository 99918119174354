import { AxiosInstance } from "axios";

export const postTextToAnswer = async (
  api: AxiosInstance,
  messages: {
    role?: string;
    content?: string;
  }[]
) => {
  const response = await api.post("/texttoanswer", { messages });
  return response.data;
};
